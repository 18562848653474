import { Alert } from 'antd';
import Icon from '@mdi/react';
import Cart from './icons/Cart';
import Spinner from './Spinner';
import { navigate } from 'gatsby';
import YouTube from 'react-youtube';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { modularScale } from '../util/modularScale';
import { AuthContext } from './context/AuthContext';
import { CartContext } from './context/CartContext';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Product as ProductInterface } from '../types/types';
import React, { useContext, useEffect, useState } from 'react';
import { gradient, grey, greyLight, slate } from '../util/theme';
import { mdiCheckCircleOutline, mdiShoppingOutline } from '@mdi/js';

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

const Columns = styled.div`
    max-width: calc(400px + 2rem);
    margin: 0 auto;
    
    @media (min-width: 900px) {
        max-width: initial;
    	display: flex;
    }
`;

const ImageColumn = styled.div`
	flex: 1 0 400px;
`;

const DescColumn = styled.div`
	flex: 1 1 100%;
`;

const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0 1rem;
	margin: 0 auto;
	
	@media (min-width: 900px) {
	    padding: 0 2rem;
	}
`;

const Section = styled.div`
    padding: 2rem 0;
    
    @media (min-width: 900px) {
        padding: 4rem 0;
    }
`;

const InnerSection = styled.div`
    max-width: 900px;
    margin: 0 auto;
`;

const VideoSection = styled(Section)`
    background-color: #fff;
    padding: 0;
    
    @media (min-width: 900px) {
        padding: 4rem 0;
    }
`

const LastSection = styled(Section)`
    margin-bottom: 60px;
    
    @media (min-width: 900px) {
        margin-bottom: 0;
    }
`

const ImageContainer = styled.div`
    position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;
	max-width: 400px;
	margin: 0 auto 1rem;
	
	@media (min-width: 900px) {
		box-shadow: 0 8px 12px 0 rgba(0,0,0,0.25);

	    &:after {
	        content: '';
	        height: 100%;
	        width: 100%;
	        position: absolute;
	        background-color: #fff;
	        top: 0.5rem;
	        left: 0.5rem;
	        z-index: -1;
	    }
	}
`

const Info = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled.h1`
    display: none;
	
	@media (min-width: 900px) {
	    order: 1;
        display: block;
        margin-bottom: 2rem;
    }
`;

const Name = styled.div`
	font-size: ${modularScale(1)};
    line-height: 1.2;
    
    @media (min-width: 900px) {
        font-size: ${modularScale(2)};
    }
`;

const Subtitle = styled.div`
	font-size: ${modularScale(-1)};
    color: ${grey};
	line-height: 1;
`;

const MobileTitle = styled.h1`
    font-size: ${modularScale(1)};
    line-height: 1.2;
    margin-bottom: 1rem;
    
    span {
        color: ${grey};
        display: block;
        font-size: ${modularScale(-1)};
    }
    
    @media (min-width: 900px) {
        display: none;
    }
`;

const Description = styled.div`
	@media (min-width: 900px) {
	    margin-bottom: 2rem;
	    order: 2;
	}
`;

const Pricing = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    
    @media (min-width: 900px) {
        flex-direction: column;
        align-items: flex-start;
	    order: 3;
	    margin-bottom: 1rem;
	    max-width: 50%;
	}
`;

const Price = styled.h2`
    display: flex;
    align-items: flex-start;
	font-size: ${modularScale(2)};
    line-height: 1.2;
    flex: 0 0 30%;
    margin: 0;
    color: ${slate};
    
    span {
        font-size: ${modularScale(0)};
        margin-top: 2px;
        
        @media (min-width: 900px) {
            font-size: ${modularScale(1)};
        }
    }
    
    @media (min-width: 900px) {
        font-size: ${modularScale(3)};
        margin-bottom: 1rem;
    }
`;

const Discount = styled(Price)`
    position: relative;
    color: #b7b7b7;
    flex: 0 0 60px;
    margin-right: 0.75rem;
    font-size: ${modularScale(1)};

    @media (min-width: 900px) {
        flex: 0 0 25px;
        margin-bottom: 0.5rem;
    }
    
    span {
        font-size: ${modularScale(1)};
    }
    
    &:after {
        position: absolute;
        content: '';
        width: calc(100% + 8px);
        height: 1px;
        background-color: #b7b7b7;
        top: 50%;
        bottom: 50%;
        left: -4px;
        right: -4px;
    }
`;

const TypeBlurb = styled.div`
    display: flex;
    flex-direction: column;
    text-align: right;
	line-height: 1;
	margin-left: auto;
    font-weight: 500;
	
	span {
	    color: ${grey};
	    display: block;
	    width: 100%;
	    font-style: italic;
	    font-weight: 400;
	}
	
	@media (min-width: 900px) {
	    text-align: left;
	    line-height: 1.5;
	    margin-left: 0;
	}
`;

const Buttons = styled.div`
	@media (min-width: 900px) {
	    order: 4
	}
`;

const Details = styled.div`
	padding: 0 1rem;
	max-width: 650px;
	margin: 0 auto;
`;

const Detail = styled.div`
	display: flex;
	align-items: center;
	border-bottom: 1px solid #d1d1d1;
	padding: 0.5rem 0;
	
	&:last-child {
	    border-bottom: none;
	}
`;

const Key = styled.div`
	
`;

const Value = styled.div`
	margin-left: auto;
	font-style: italic;
`;

const VideoWrapper = styled.div`
    position: relative; 
    width: 100%;
    max-width: 900px;
    overflow: hidden;
    margin: 0 auto;
    z-index: 1;
    
    &::after {
        padding-top: 56.25%;
        display: block;
        content: '';
    }
    
    iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;  
    }
`

const CTAButton = styled(Button)`
	width: 100%;
	margin-bottom: 0.5rem;
	padding: 0.75rem;
	font-size: ${modularScale(0)};
`;

const BuyButton = styled(CTAButton)`
    display: none;
    color: #fff;
	background: ${gradient};
	margin-bottom: 1rem;
	
	&:focus, &:hover, &:active {
	    background: ${gradient};
	}
	
	@media (min-width: 900px) {
	    display: block;
	    margin-bottom: 0;
	    max-width: 50%;
	}
`;

const StyledAlert = styled(Alert)`
	width: 100%;
	margin-bottom: 1rem;
`;

const CartButton = styled(CTAButton)`
	background-color: #fff;
	margin-bottom: 2rem;
	
	&:focus, &:hover, &:active {
	    background-color: #fff;
	}
	
	svg {
	    position: absolute;
	    width: 24px;
	    right: 1rem;
	    top: auto;
	    bottom: auto;
	}
	
	@media (min-width: 900px) {
	    margin-bottom: 1rem;
	    max-width: 50%;
	}
`;

const StickyButton = styled(Button)`
	width: 100%;
	background: ${gradient};
	color: #fff;
	padding: 1rem;
	margin-top: auto;
	font-size: ${modularScale(0)};
	border-radius: 0;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 2;
	
	&:active, &:focus, &:hover {
	    color: #fff;
	}
	
	svg {
	    position: absolute;
	    width: 24px;
	    right: 1rem;
	    top: auto;
	    bottom: auto;
	}
	
	@media (min-width: 900px) {
	    display: none;
	}
`;

const RotatingSpinner = styled(Spinner)`
    width: 24px;
`;

const opts = {
    height: '360',
    width: '640',
    playerVars: {
        // https://developers.google.com/youtube/player_parameters
    }
}

interface Props {
    product: ProductInterface;
}

const Product: React.FC<Props> = ({ product }) => {
    const { cartItems, addProduct } = useContext(CartContext);
    const [isAdded, setIsAdded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const image = getImage(product.localImage);

    useEffect(() => {
        if (cartItems.find(i => product.productId === i.productId)) {
            setIsAdded(true);
        }
    }, [cartItems]);

    const addProductToCart = async () => {
        if (cartItems.find(i => product.productId === i.productId)){
            return;
        }

        addProduct(product);
        setIsAdded(true);

        setIsLoading(false);
    }

    const buyNow = () => {
        if (cartItems.find(i => product.productId === i.productId)){
            navigate('/checkout');
            return;
        }

        addProduct(product);
        navigate('/checkout');
    }

    const addIcon = () => {
        if (isLoading) return <RotatingSpinner color={'#b1b1b1'} />
        if (isAdded) return <Icon path={mdiCheckCircleOutline} />
        return <Cart />
    }

    const renderPrice = () => {
        const price = product.price.toString();

        const dollars = price.substr(0, price.length - 2);
        const cents = price.substr(price.length - 2, 2);

        return <Price>$ {dollars}<span>.{cents}</span></Price>
    }

    const renderDiscount = () => {
        return <Discount>$ 9.99</Discount>
    }

    return (
        <Container>
            <Section>
                <InnerSection>
                <Columns>
                    <ImageColumn>
                        <InfoContainer>
                            <MobileTitle><span>{product.subtitle}</span> {product.title}</MobileTitle>
                        </InfoContainer>
                        <ImageContainer>
                            {image && <GatsbyImage alt={product.title + ' Sheet Music'} image={image} />}
                        </ImageContainer>
                    </ImageColumn>
                    <DescColumn>
                        <InfoContainer>
                            <Info>
                                <Title>
                                    <Subtitle>{product.subtitle}</Subtitle>
                                    <Name>{product.title}</Name>
                                </Title>
                                <Pricing>
                                    {/*{renderDiscount()}*/}
                                    {renderPrice()}
                                    <TypeBlurb>{product.category} <span>PDF Download</span></TypeBlurb>
                                </Pricing>
                                { error && <StyledAlert message={error}/>}
                                <Buttons>
                                    <CartButton variant="contained" onClick={addProductToCart}>Add to cart {addIcon()}</CartButton>
                                    <BuyButton variant="contained" onClick={buyNow}>Buy now</BuyButton>
                                </Buttons>
                                <Description>{product.desc}</Description>
                            </Info>
                            <StickyButton onClick={buyNow}>Buy Now <Icon path={mdiShoppingOutline}/></StickyButton>
                        </InfoContainer>
                    </DescColumn>
                </Columns>
                </InnerSection>
            </Section>

            { product.video &&
                <VideoSection>
                    <InnerSection>
                        <VideoWrapper>
                            <YouTube videoId={product.video} opts={opts} />
                        </VideoWrapper>
                    </InnerSection>
                </VideoSection>
            }

            <LastSection>
                <InnerSection>
                    <Details>
                        <Detail><Key>Category</Key><Value>{product.category}</Value></Detail>
                        <Detail><Key>Difficulty</Key><Value>{product.difficulty}</Value></Detail>
                        <Detail><Key>Pages</Key><Value>{product.pages}</Value></Detail>
                        <Detail><Key>Length</Key><Value>{product.length}</Value></Detail>
                        <Detail><Key>Product Type</Key><Value>Digital Download</Value></Detail>
                        <Detail><Key>Product Format</Key><Value>{product.format}</Value></Detail>
                    </Details>
                </InnerSection>
            </LastSection>
        </Container>
    );
};

export default Product;
