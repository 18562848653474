import React from 'react';
import Layout from '../components/layouts/Layout';
import ProductComponent from '../components/Product';
import { Product, ProductSEO } from '../types/types';
import SEO from '../components/seo/SEO';
import { graphql } from 'gatsby';

interface Props {
    data: {
        productPage: Product,
        productSEO: ProductSEO,
    };
}

const ProductTemplate: React.FC<Props> = ({ data }) => {
    const { productPage, productSEO } = data;

    return (
        <Layout customSEO>
            <SEO
                title={`${productSEO.subtitle} "${productSEO.title}" Sheet Music`}
                published={productSEO.created_at}
                banner={productSEO.localImage.childImageSharp.resize.src}
                desc={productSEO.desc}
            />
            <ProductComponent product={productPage} />
        </Layout>
    );
};

export default ProductTemplate;

export const query = graphql`
    query($slug: String!) {
        productPage: product(slug: {eq: $slug}) {
            productId
            category
            created_at
            desc
            difficulty
            format
            pages
            length
            pdf
            price
            subtitle
            slug
            thumb
            title
            updated_at
            video
            localImage {
                childImageSharp {
                    gatsbyImageData(
                        width: 400
                        layout: CONSTRAINED
                        placeholder: DOMINANT_COLOR
                        formats: [AUTO, WEBP]
                    )
                }
            }
        }
        productSEO: product(slug: {eq: $slug}) {
            title,
            subtitle,
            created_at,
            desc,
            localImage {
                childImageSharp {
                    resize(
                        width: 1200
                        height: 630
                        cropFocus: NORTH
                    ) {
                        src
                    }
                }
            }
        }
    }
`;
